import React from 'react'
import useCategories from '../useCategories'

const CompanyAddress = () => {

    const { addressLine1, addressLine2, companyPhone, addressCityStateZip, companyEmail, copyright } = useCategories()

    const centerText = { textAlign: 'center' }

    return (
        <>
            <div style={centerText}>
                <p>{addressLine1}</p>
                <p>{addressLine2}</p>
                <p>{addressCityStateZip}</p>
                <p>{companyPhone}</p>
                <p>{companyEmail}</p>
                <p>{copyright}</p>
            </div>
        </>
    )
}

export default CompanyAddress
