import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LocaleContext } from './Layout';

function useCategories() {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = React.useContext(LocaleContext)
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query)

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      pageText: item.node.page_text,
    }
  });

  const { pageText } = simplified[0]

  return pageText
}

export default useCategories

const query = graphql`
  query useCategories {
    rawData: allFile(
      filter: { sourceInstanceName: { eq: "categories" } }
    ) {
      edges {
        node {
          name
          page_text: childCategoriesJson {
            home

            hello
            subline
            latestPosts
            pageTitle
            path
            allPosts
            toRead

            next
            prev
            of

            button

            addressLine1
            addressLine2
            companyPhone
            addressCityStateZip
            companyEmail
            copyright

            joinOurNewsletter
          }
        }
      }
    }
  }
`;
