import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import GlobalStyles from '../../styles/global';

import * as S from './styled';

const LocaleContext = React.createContext();

const Layout = ({ children, pageContext: { locale } }) => (
  <LocaleContext.Provider value={{ locale }}>
    <GlobalStyles />
    <S.Wrapper>
      <Header />
      <S.SiteContent role="main">
        <S.Container>{children}</S.Container>
      </S.SiteContent>
      <Footer />
    </S.Wrapper>
  </LocaleContext.Provider>
);

export { Layout, LocaleContext };
